<template>
  <section
    v-if="profile.isAdmin || profile.permissions.indexOf('viewHome') !== -1"
    style="letter-spacing: normal !important"
  >
    <b-row v-if="profile.isAdmin">
      <b-col md="12">
        <div class="d-flex mb-1 justify-content-end font-weight-bolder">
          <div
            class="subscription-info"
            :style="styleSubscriptionBlock"
          >
            <div class="d-flex">
              <div class="border-right-primary pr-1">
                <span style="color: #4c00ff;">
                  <feather-icon
                    icon="CalendarIcon"
                    size="12"
                    class="mr-25"
                  /> {{ $t('startSubscraptionDate') }} : </span>
                <label class="mt-25 text-center main-font-size">
                  {{ getDate(profile.subscriptionStartDate) }} </label>
              </div>

              <div class="pl-1">
                <span style="color: #4c00ff;">
                  <feather-icon
                    icon="CalendarIcon"
                    size="12"
                    class="mr-25"
                  /> {{ $t('endSubscraptionDate') }} : </span>
                <label class="mt-25 text-center main-font-size">
                  {{ getDate(profile.subscriptionEndDate) }}</label>
              </div>
            </div>
          </div>

          <div
            v-if="isSubscriptionHaveLessThanMonth"
            class="text-center d-flex"
          >
            <div>
              <span>
                {{ $t('remainder') }} </span>
              <flip-countdown
                class="mt-50"
                :deadline="`${getDate(profile.subscriptionEndDate)} ${getTime(profile.subscriptionEndDate)}`"
                :labels="counterLables"
                :show-hours="false"
                :show-minutes="false"
                :show-seconds="false"
                countdown-size="22px"
              />
            </div>

            <div
              class="d-flex flex-column"
            >
              <span>{{ $t('forRenewSubscription') }}</span>
              <b-button
                class="btn-sm mt-50"
                variant="success"
                href="http://wa.me/966564460371"
                target="_blank"
              >
                <span class="align-middle">{{ $t('contactWithUs') }}</span>
                <img
                  src="@/assets/images/icons/whatsapp.png"
                  width="18"
                  class="ml-50"
                >
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="UsersIcon"
          color="primary"
          :statistic="lookups.totalStudentNumbers.total"
          :buttonName="'addStudent'"
          :routeName="'student-new'"
          statisticTitle="students"
          :permission="'addStudents'"
        />
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="ShoppingCartIcon"
          color="danger"
          :statistic="lookups.topSalesTransactions.totalPrice"
          :buttonName="'addInvoice'"
          :routeName="'pos-invoice'"
          statisticTitle="invoices"
          :permission="'addSalesTransactions'"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="CreditCardIcon"
          color="success"
          :statistic="lookups.topCollectiontVoucher.totalValue"
          :buttonName="'collection-transactions-new'"
          :routeName="'collection-transactions-new'"
          statisticTitle="customersNotice"
          :permission="'addCollectionTransactions'"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          icon="PackageIcon"
          color="warning"
          :statistic="lookups.topPaymentVoucher.totalValue"
          :buttonName="'payment-transactions-new'"
          :routeName="'payment-transactions-new'"
          statisticTitle="paymentTransactions"
          :permission="'addPaymentTransactions'"
        />
      </b-col>
    </b-row>

    <b-row class="pt-1">
      <b-col
        lg="4"
        order-lg="1"
        order="2"
        class="d-flex justify-content-between flex-column col-md-12"
      >
        <!-- students per stage pie chart -->
        <b-row>
          <b-col md="12">
            <b-card
              no-body
              class="card-statistics p-50"
            >
              <b-card-header>
                <b-card-title class="card-title">
                  {{ $t('studentsNumberPerStage') }}
                </b-card-title>
              </b-card-header>
              <vue-apex-charts
                type="donut"
                height="200"
                :options="studentsPerStage.chartOptions"
                :series="studentsPerStage.series"
                :dir="isRight ? 'rtl' : 'ltr'"
              />
            </b-card>
          </b-col>
        </b-row>

        <!-- top discounts pie chart -->
        <b-row>
          <b-col md="12">
            <b-card
              no-body
              class="card-statistics p-50"
            >
              <b-card-header>
                <b-card-title class="card-title">
                  {{ $t('totalDiscountsPerType') }}
                </b-card-title>
              </b-card-header>
              <vue-apex-charts
                type="pie"
                height="200"
                :options="discountsPerType.chartOptions"
                :series="discountsPerType.series"
                :dir="isRight ? 'rtl' : 'ltr'"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <!-- top services -->
      <b-col
        lg="8"
        order-lg="1"
        order="2"
        class="d-flex justify-content-between flex-column"
      >
        <b-card
          no-body
          class="card-statistics p-50"
        >
          <b-card-header>
            <b-card-title>{{ $t("top5ServicesAccordingToPriceAndQuantity") }} </b-card-title>
          </b-card-header>
          <vue-apex-charts
            type="pie"
            height="250"
            :options="topServices.chartOptions"
            :series="topServices.series"
            :dir="isRight ? 'rtl' : 'ltr'"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row class="pt-1">
      <!-- top Expenses -->
      <b-col
        lg="6"
        order-lg="1"
        order="2"
        class="d-flex justify-content-between flex-column col-md-12"
      >
        <b-card
          no-body
          class="card-statistics p-50"
        >
          <b-card-header>
            <b-card-title>{{ $t("income") }} </b-card-title>
          </b-card-header>
          <vue-apex-charts
            type="area"
            height="250"
            :options="topIncomes.chartOptions"
            :series="topIncomes.series"
            :dir="isRight ? 'rtl' : 'ltr'"
          />
        </b-card>
      </b-col>

      <!-- top Income -->
      <b-col
        lg="6"
        order-lg="1"
        order="2"
        class="d-flex justify-content-between flex-column"
      >
        <b-card
          no-body
          class="card-statistics p-50"
        >
          <b-card-header>
            <b-card-title>{{ $t("expense") }} </b-card-title>
          </b-card-header>
          <vue-apex-charts
            ref="realtimeChart"
            type="area"
            height="250"
            :options="topExpenses.chartOptions"
            :series="topExpenses.series"
            :dir="isRight ? 'rtl' : 'ltr'"
          />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script
  scr="https://cdnjs.cloudflare.com/ajax/libs/intro.js/5.1.0/introjs.min.css"
></script>
<script>
// import { BCard, BCol } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import Ripple from 'vue-ripple-directive';
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue';
import FlipCountdown from 'vue2-flip-countdown';
import { $themeColors } from '@themeConfig';
import { kFormatter } from '@core/utils/filter';
import { optionsType } from '@/libs/acl/Lookups';
import flatPickr from 'vue-flatpickr-component';
import introJs from 'intro.js/intro';
import 'intro.js/introjs-rtl.css';

export default {
  components: {
    VueApexCharts,
    FlipCountdown,
    StatisticCardWithAreaChart,
    // BCard,
    // BCol,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      diffInDays: 0,
      counterLables: {
          days: 'يوم',
          hours: 'ساعة',
          minutes: 'دقيقة',
          seconds: 'ثانية'
      },
      categories: [
        {
          name: 'Series 1',
          data: [45, 52, 38, 45, 19, 23, 2],
        },
      ],

      selectedItem: {
        rangeOptions: 'lastYear',
        ollectionsTotalValue: { totalValue: 0 },
        paymentsTotalValue: { totalValue: 0 },
        topCustomersCurrentDebit: [],
        topDealersTotalPurch: [],
        topDealersTotalSales: [],
        topItemsTotalSales: [],
        topSupplierCurrentDebit: [],
        totalNetPurch: { totalNet: 0 },
        totalNetSales: { totalNet: 0 },
      },

      lookups: {
        totalStudentNumbers: {},
        topSalesTransactions: {},
        topExpensesTransactions: [],
        topIncomeTransactions: [],
        topServicesValueAndQuantity: [],
        totalStudentsPerStage: [],
        totalDiscountsPerType: [],
        topCollectiontVoucher: {},
        topPaymentVoucher: {}
      },

      chartOptions: {
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },

      series: [
        {
          name: 'Vue Chart',
          data: [30, 40, 45, 50, 49, 60, 70, 81],
        },
      ],

      topIncomes: {
        chartOptions: {
          chart: {
            type: 'area',
            height: 200,
          },
        },
      },

      topExpenses: {
        chartOptions: {
          chart: {
            type: 'area',
            height: 200,
          },
        },
      },

      topServices: {
        chartOptions: {
          chart: {
            type: 'bar',
            height: 200,
          },
        },
      },

      studentsPerStage: {
        series: [10, 20, 30, 40, 50],
        chartOptions: {
          chart: {
            type: 'donut',
            height: 200,
          },
        },
      },

      discountsPerType: {
        series: [10, 20, 30, 40, 50],
        chartOptions: {
          chart: {
            type: 'pie',
            height: 200,
          },
        },
      },

    };
  },
  beforeMount() {
    this.get({ url: 'licensing/gl-status' }).then((data) => {
      localStorage.accountsEnabled = JSON.stringify(data.enabled);
      localStorage.storesEnabled = JSON.stringify(data.storeEnable);
    });
  },
  mounted() {
    this.setLast30Day();
    this.getData();
  },
  computed: {
    isSubscriptionHaveLessThanMonth() {
      return this.diffInDays <= 30 && this.diffInDays >= 0
    },

    styleSubscriptionBlock() {
     return { paddingTop: this.isSubscriptionHaveLessThanMonth ? '30px' : '5px' }
    }
  },
  methods: {
    kFormatter,
    getData() {
      this.get({ url: `aggregates/DashBoardData` }).then((data) => {
        this.lookups = data;
        this.updatechart();
      });
    },

    setLast30Day() {
      const currentDay = new Date(this.today);
      const expireDate = new Date(this.profile.subscriptionEndDate);
      const daysDiffInMS = expireDate.getTime() - currentDay.getTime();
      const daysInMS = 1000 * 60 * 60 * 24;
      this.diffInDays = Math.round(daysDiffInMS / daysInMS);
    },

    updatechart() {
      this.studentsPerStage = {
        chartOptions: {
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%'
                    }
                  }
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
        series: this.lookups.totalStudentsPerStage.map((item) => item.totalStudentsNumber),
      }
      this.studentsPerStage.chartOptions = {
        labels: this.lookups.totalStudentsPerStage.map((item) => item.stageArabicName)
      };

      this.discountsPerType = {
        chartOptions: {
          responsive: [
          {
            breakpoint: 480,
            options: {
              plotOptions: {
                pie: {
                  donut: {
                    size: '100%'
                  }
                }
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        },
        series: this.lookups.totalDiscountsPerType.map((item) => item.totalPrice)
      }
      this.discountsPerType.chartOptions = {
        labels: this.lookups.totalDiscountsPerType.map((item) => item.arabicName)
      }

      this.topIncomes = {
        series: [
          {
            name: this.$t('value'),
            data: this.lookups.topIncomeTransactions.map(item => ({ x: item.arabicName, y: item.totalValue })),
          },
        ],
        chartOptions: {
          chart: {
            height: 380,
            type: 'area',
            toolbar: {
              show: false,
            },
            dropShadow: {
              enabled: true,
              enabledOnSeries: [0, 1, 2],
              top: -10,
              left: 0,
              blur: 10,
              color: '#fbcab7',
              opacity: 0.4,
            },
          },
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: ['#fff'],
            width: 1.5,
            dashArray: 0,
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              show: true,
              minWidth: 0,
              maxWidth: 160,
              style: {
                fontSize: '12px',
                fontFamily: 'Cairo',
                colors: [
                  '#000'
                ],
              },
              offsetX: -20,
              offsetY: 0,
              rotate: 0,
            },
          },
          xaxis: {
            type: 'category',
            labels: {
              rotate: -30,
              minHeight: 0,
              maxHeight: 60,
              style: {
                fontSize: '13px',
                fontFamily: 'Cairo',
                colors: [
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae'
                ],
              },
            },
          },
          fill: {
            opacity: 1
          },
          colors: ['rgba(115, 103, 240, 0.7)'],
          tooltip: {
            y: {
              formatter: (val) => {
                return `${val}`;
              },
            },
            theme: 'dark',
            style: {
                fontSize: '12px',
                fontFamily: 'Cairo'
              },
          },
        },
      };

      this.topExpenses = {
        series: [
          {
            name: this.$t('value'),
            data: this.lookups.topExpensesTransactions.map(item => ({ x: item.arabicName, y: item.totalValue })),
          },
        ],
        chartOptions: {
          chart: {
            height: 380,
            type: 'area',
            toolbar: {
              show: false,
            },
            dropShadow: {
              enabled: true,
              enabledOnSeries: [0, 1, 2],
              top: -10,
              left: 0,
              blur: 20,
              color: '#fbcab7',
              opacity: 0.6,
            },
          },
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: ['#fff'],
            width: 1.5,
            dashArray: 0,
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              show: true,
              minWidth: 0,
              maxWidth: 160,
              style: {
                fontSize: '12px',
                fontFamily: 'Cairo',
                colors: [
                  '#000'
                ],
              },
              offsetX: -20,
              offsetY: 0,
              rotate: 0,
            },
          },
          xaxis: {
            type: 'category',
            labels: {
              rotate: -30,
              minHeight: 0,
              maxHeight: 60,
              style: {
                fontSize: '13px',
                fontFamily: 'Cairo',
                colors: [
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae'
                ],
              },
            },
          },
          fill: {
            opacity: 1
          },
          colors: ['rgba(204, 21, 18, 0.5)'],
          tooltip: {
            y: {
              formatter: (val) => {
                return `${val}`;
              },
            },
            theme: 'dark',
            style: {
                fontSize: '12px',
                fontFamily: 'Cairo'
              },
          },
        },
      };

      this.topServices = {
        series: [
          {
            name: this.$t("quantity"),
            data: this.lookups.topServicesValueAndQuantity.map((item) => ({
              x: item.arabicName,
              y: item.totalQuantity,
            })),
          },
          {
            name: this.$t("value"),
            data: this.lookups.topServicesValueAndQuantity.map((item) => ({
              x: item.arabicName,
              y: item.totalPrice,
            })),
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 515
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '50%',
              endingShape: "rounded"
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['transparent'],
          },
          yaxis: {
            tickAmount: 6,
            labels: {
              show: true,
              minWidth: 0,
              maxWidth: 160,
              style: {
                fontSize: '12px',
                fontFamily: 'Cairo',
                colors: [
                  '#000'
                ],
              },
              offsetX: -20,
              offsetY: 0,
              rotate: 0,
            },
          },
          xaxis: {
            type: 'category',
            tickPlacement: 'between',
            position: 'bottom',
            labels: {
              rotate: -30,
              minHeight: 0,
              maxHeight: 60,
              style: {
                fontSize: '13px',
                fontFamily: 'Cairo',
                colors: [
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae',
                  '#8b9bae'
                ],
              },
            },
          },
          fill: {
            opacity: 1
          },
          colors: [$themeColors.primary, $themeColors.warning],
          tooltip: {
            y: {
              formatter: (val) => {
                return `${val}`;
              },
            },
            theme: 'dark',
            style: {
                fontSize: '12px',
                fontFamily: 'Cairo'
              },
          },
        },
      };
    },
  },
};
</script>

<style lang="css">
.flip-clock {
  text-align: center;
  perspective: 600px;
  /* text-align: end; */
}
.flip-card__bottom[data-v-cff81de8],
.flip-card__back-bottom[data-v-cff81de8],
.flip-card__bottom-4digits[data-v-cff81de8],
.flip-card__back-bottom-4digits[data-v-cff81de8] {
  color: #f8f8f8 !important;
}
.flip-card__top[data-v-cff81de8],
.flip-card__bottom[data-v-cff81de8],
.flip-card__back-bottom[data-v-cff81de8],
.flip-card__back[data-v-cff81de8]::before,
.flip-card__back[data-v-cff81de8]::after {
  color: #f8f8f8 !important;
}
.flip-clock[data-v-cff81de8] {
  padding: 0 !important;
}
.btn-whatsApp {
  border-color: #25d366 !important;
  background-color: #25d366 !important;
}
.apexcharts-legend-text {
  font-family: 'Cairo' !important;
  padding-left: 5px;
}

.card-title {
  margin: 0 auto;
  border: 1px solid #EEE;
  padding: 10px;
}

.subscription-info {
  background-color: #7367f01f;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  padding-top: 20px;
}
</style>
